export const NavLinkContent = [
    {
        id: 1,
        titre: "ACCUEIL",
        link: "/"
    },
    {
        id: 2,
        titre: "CARTE",
        link: "/carte"
    },
    {
        id: 3,
        titre: "GALERIE",
        link: "/#galerie"
    },
    {
        id: 4,
        titre: "PANIER",
        link: "/panier"
    },
]